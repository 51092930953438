import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/filters';
import '@/directives';
import VueMeta from 'vue-meta';
import VueMixpanel from 'vue-mixpanel';
import VCalendar from 'v-calendar';
Vue.use(VueMixpanel, {
  token: process.env.VUE_APP_MIXPANEL_KEY,
  config: {
    debug: process.env.NODE_ENV !== 'production'
  }
});

Vue.use(VCalendar, {});

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
Vue.config.productionTip = false;
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faAbacus,
  faArrowRightLong,
  faArrowLeftLong,
  faCalendarDay,
  faLayerGroup,
  faUserGroup,
  faShoppingBasket,
  faBoxesStacked,
  faBoxOpen,
  faBox,
  faBolt,
  faXmark,
  faCheck,
  faClone,
  faChartMixed,
  faBoxCheck,
  faTruckRampBox,
  faWeightHanging,
  faImagePolaroid,
  faPrint,
  faWrench,
  faAngleDown,
  faEllipsisV,
  faPauseCircle,
  faBan,
  faStars,
  faMessageLines,
  faBarcodeRead,
  faRobot,
  faCircleInfo,
  faPencilAlt,
  faUsers,
  faMinus,
  faPlus,
  faChevronDown,
  faThumbsUp,
  faTrashCan,
  faCrystalBall,
  faFlagSwallowtail,
  faDumpster,
  faHouseHeart,
  faHouseUser,
  faUserSlash,
  faTable,
  faPalletBoxes,
  faLockOpen,
  faLock,
  faFaceThinking,
  faIdBadge,
  faEye,
  faEyeSlash,
  faEllipsis,
  faFileArrowDown,
  faMagnifyingGlass,
  faInbox,
  faFlag,
  faTicketSimple,
  faBadgePercent,
  faClockDesk,
  faStar as falStar,
  faHand,
  faCamera,
  faShieldCat,
  faCameraSlash,
  faCirclePause,
  faFaceSadTear,
  faFileInvoice
} from '@fortawesome/pro-light-svg-icons';
import {
  faBoxCheck as fadBoxCheck,
  faBoxOpen as fadBoxOpen,
  faTruckRampBox as fadTruckRampBox,
  faTriangleExclamation,
  faFlagSwallowtail as fadFlagSwallowtail,
  faStar
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faAbacus,
  faArrowRightLong,
  faArrowLeftLong,
  faCalendarDay,
  faChartMixed,
  faLayerGroup,
  faUserGroup,
  faShoppingBasket,
  faBoxesStacked,
  faMinus,
  faPlus,
  faClone,
  faUsers,
  faBolt,
  faXmark,
  faCheck,
  faBoxCheck,
  fadBoxCheck,
  faChevronDown,
  faBoxOpen,
  fadBoxOpen,
  faTrashCan,
  faTruckRampBox,
  fadTruckRampBox,
  faWeightHanging,
  faImagePolaroid,
  faPrint,
  faWrench,
  faAngleDown,
  faEllipsisV,
  faPauseCircle,
  faBan,
  faMessageLines,
  faBarcodeRead,
  faStars,
  faStar,
  falStar,
  faRobot,
  faTriangleExclamation,
  faCircleInfo,
  faPencilAlt,
  faCrystalBall,
  faThumbsUp,
  faFlagSwallowtail,
  fadFlagSwallowtail,
  faDumpster,
  faHouseHeart,
  faHouseUser,
  faUserSlash,
  faTable,
  faPalletBoxes,
  faLockOpen,
  faLock,
  faFaceThinking,
  faIdBadge,
  faEye,
  faEyeSlash,
  faEllipsis,
  faFileArrowDown,
  faMagnifyingGlass,
  faInbox,
  faBox,
  faFlag,
  faTicketSimple,
  faBadgePercent,
  faClockDesk,
  faHand,
  faCamera,
  faShieldCat,
  faCameraSlash,
  faCirclePause,
  faFaceSadTear,
  faUserSlash,
  faFileInvoice
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
