const checklist = [
  {
    label: 'Pack Box',
    isRequired: true,
    isCompleted: false,
    userCan: false,
    key: 'I_PB'
  },
  // {
  //   label: 'Add Bonus Items Flyer',
  //   isRequired: true,
  //   isCompleted: false,
  //   userCan: true,
  //   key: 'I_BO'
  // },
  {
    label: 'Add Taster Box',
    isRequired: true,
    isCompleted: false,
    userCan: false,
    key: 'I_TB'
  },

  // {
  //   label: 'Add Welcome Note',
  //   isRequired: true,
  //   isCompleted: false,
  //   userCan: true,
  //   key: 'I_WC'
  // },
  // {
  //   label: 'Add A5 Flyer',
  //   isRequired: true,
  //   isCompleted: false,
  //   userCan: true,
  //   key: 'I_FL'
  // },
  // {
  //   label: 'Add Bonus Food Flyer',
  //   isRequired: true,
  //   isCompleted: false,
  //   userCan: true,
  //   key: 'I_BF'
  // },
  // {
  //   label: 'Add Checklist Flyer',
  //   isRequired: true,
  //   isCompleted: false,
  //   userCan: true,
  //   key: 'I_CLF'
  // },

  {
    label: 'Print Delivery Label',
    isRequired: true,
    isCompleted: false,
    userCan: false,
    key: 'I_PL'
  }
];

const skuOrder = [
  'W_PCH_CHK',
  'W_PCH_TUN',
  'W_PCH_OCN',
  'W_PCH_CBF',
  'W_PCH_CDU',
  'W_PCH_CHK_KIT',
  'W_PCH_TUN_KIT',
  'W_PCH_CBF_KIT',
  'W_PCH_CDU_KIT',
  'W_PCH_CHKG_TCH',
  'W_PCH_CHKJ_TCH',
  'W_PCH_SLMG_TCH',
  'W_PCH_SLMJ_TCH',
  'W_CAN_TUN_DLX',
  'W_CAN_MKL_DLX',
  'W_CAN_FMD_DLX',
  'W_CAN_CHM_DLX',
  'W_CAN_CHK_DLX',
  'W_CAN_TUS_DLX',
  'W_CAN_CDU_DLX',
  'W_CAN_IRC_DLX',
  'W_CAN_FST_DLX',
  'W_CAN_SAM_DLX',
  'W_CAN_CHK_SMT',
  'W_CAN_CWS_SMT',
  'W_TRA_CHK',
  'W_TRA_COS',
  'D_BAG_CHK',
  'D_BAG_TRK',
  'D_BAG_SLM',
  'D_BAG_CHK_KIT'
];

export { checklist, skuOrder };
