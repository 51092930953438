import http from '@/http';
import { includes } from 'lodash/fp';
const stateObj = {
  status: '',
  token: localStorage.getItem('token') || '',
  isAdmin: null,
  isCustomerService: null
};

const mutations = {
  auth_request(state) {
    state.status = 'loading';
  },
  auth_success(state, token) {
    state.status = 'success';
    state.token = token;
  },
  auth_error(state) {
    state.status = 'error';
  },
  check_scopes(state) {
    const scope = atob(state.token.split('.')[1]);
    state.isAdmin = includes('admin', JSON.parse(scope).scope);
    state.isCustomerService = includes(
      'customerservice',
      JSON.parse(scope).scope
    );
  },
  logout(state) {
    state.status = '';
    state.token = '';
    state.user = '';
  }
};
const actions = {
  login({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request');
      http
        .post('packers/login', user)
        .then(resp => {
          const token = 'Bearer ' + resp.data.content[0];
          localStorage.setItem('token', token);
          http.defaults.headers.common['Authorization'] = token;
          dispatch('getProducts');

          dispatch('getPackingList');
          commit('auth_success', token);
          resolve(resp);
        })
        .catch(err => {
          commit('auth_error');
          localStorage.removeItem('token');
          reject(err);
        });
    });
  },

  logout({ commit }) {
    return new Promise(resolve => {
      commit('logout');
      localStorage.removeItem('token');
      sessionStorage.clear();
      localStorage.removeItem('cats');

      delete http.defaults.headers.common['Authorization'];
      resolve();
    });
  }
};

const getters = {
  isLoggedIn: state => !!state.token,
  isAdmin: state => state.isAdmin,
  isCustomerService: state => state.isCustomerService
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
