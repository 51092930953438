import http from '@/http';
import utils from '@/utils';

const stateObj = {
  catsList: []
};

const mutations = {};
const actions = {
  getCatsList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http
        .get(utils.sanitizeApiUrl(`/api/packers/cat_images`), {
          noTimestamp: true,
          params: payload.params
        })
        .then(resp => {
          resolve(resp.data.content);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  approveCatImage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      console.log('payload', payload);
      http
        .put(utils.sanitizeApiUrl(`/api/packers/cat/${payload.id}/approve`), {
          approve: payload.approve
        })
        .then(resp => {
          resolve(resp.data.content);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const getters = {
  catsListGetter: state => state.churnList
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
