<template>
  <div
    ref="avatarWrapper"
    class="rounded-full overflow-hidden bg-white shadow-xl flex items-center"
    :class="styles"
  >
    <img ref="avatar" class="w-full" :src="avatar" />
  </div>
</template>

<script>
import md5 from "md5";
import { createAvatar } from '@dicebear/core';
import { identicon } from '@dicebear/collection';

export default {
  name: 'Avatar',
  props: {
    email: String,
    size: {
      type: String,
      default: "md"
    }
  },
  data() {
    return {
      options: {
        base64: true
      }
    };
  },
  computed: {
    hash() {
      return md5(this.email.trim().toLowerCase());
    },
    avatar() {
      const avatar = createAvatar(identicon, {
      seed: this.hash, 
});
      return avatar.toDataUriSync();;
    },
    styles() {
      if (this.size === "xs") {
        return "w-4 h-4";
      }
      if (this.size === "sm") {
        return "w-5 h-5";
      }
      if (this.size === "md") {
        return "w-10 h-10";
      }
      return "w-16";
    }
  }
};
</script>

<style lang="css" scoped></style>
