import http from '@/http';
import utils from '@/utils';
const stateObj = {};

const mutations = {
  update_user_credit_balance(state, payload) {
    this.state.core.currentUserProfile.user.credit = payload;
  }
};
const actions = {
  updateCredit({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http
        .put(
          utils.sanitizeApiUrl(
            `api/packers/user/${payload.userId}/${payload.type}`
          ),
          {
            amount: payload.credit
          }
        )
        .then(resp => {
          commit('update_user_credit_balance', resp.data.content[0].balance);
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  usersCreditList() {
    return new Promise((resolve, reject) => {
      http
        .get(utils.sanitizeApiUrl(`api/packers/users/credit`))
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const getters = {};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
