import http from '@/http';
import utils from '@/utils';

const stateObj = {};

const mutations = {};
const actions = {
  downloadLabel(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .get(utils.sanitizeApiUrl(payload), { responseType: 'blob' })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const getters = {};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
