import http from '@/http';

const stateObj = {
  predictions: {
    stats: null,
    products: [],
    months: [],
    dispatch: []
  }
};

const mutations = {
  set_stock_predictions(state, payload) {
    state.predictions.stats = payload[0];
    state.predictions.products = payload[1];
    state.predictions.months = payload[2];
  },
  set_dispatch_predictions(state, payload) {
    state.predictions.dispatch = payload;
  }
};
const actions = {
  getStockPredictions({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get('packers/stockpredictions')
        .then(response => {
          commit('set_stock_predictions', response.data.content);

          resolve(response);
        })
        .catch(err => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  },
  updateSubscriberPredictions({ commit }, data) {
    return new Promise((resolve, reject) => {
      console.log(commit);
      http
        .gput('packers/stock/subscribers', {
          count: data.count,
          date: data.date
        })
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  },
  getDispatchPredictions({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get('packers/monthlydispatch')
        .then(response => {
          commit('set_dispatch_predictions', response.data.content);

          resolve(response);
        })
        .catch(err => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  }
};

const getters = {
  getCurrentPredictions: state => {
    return state.predictions;
  },
  getDispatchPredictions: state => {
    return state.predictions.dispatch;
  }
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
