import _ from 'lodash';
import {
  find,
  replace,
  groupBy,
  cloneDeep,
  includes,
  uniq,
  isNaN
} from 'lodash/fp';
import moment from 'moment';
import store from '@/store';
const foodTypes = [
  { key: 'wet_pouch', display: 'Pouches' },
  { key: 'wet_tasty', display: 'Tasty Slices' },
  { key: 'wet_chunk', display: 'Tasty Chunks' },
  { key: 'wet_tray', display: 'Trays' },
  { key: 'wet_can', display: 'Cans' },
  { key: 'wet_smooth', display: 'Smooth' },
  { key: 'dry', display: 'Dry Food' },
  { key: 'box', display: 'Boxes' },
  { key: 'tape', display: 'Tape' },
  { key: 'srp', display: 'Consumable' },
  { key: 'treat', display: 'Treat' },
  { key: 'box_insert', display: 'Flyers' },
  { key: 'taster', display: 'Taster Boxes' }
];
function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function setUrlParameter(key, value) {
  var queryParams = new URLSearchParams(window.location.search);
  queryParams.set(key, value);

  history.replaceState(null, null, '?' + queryParams.toString());
}

function removeUrlParameter(key) {
  var searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(key);
  var newRelativePathQuery =
    searchParams.size >= 1
      ? window.location.pathname + '?' + searchParams.toString()
      : window.location.pathname;
  history.pushState(null, '', newRelativePathQuery);
}

function flattenProducts(products) {
  let list = [];
  _.forEach(products, function (value) {
    _.forEach(value, function (a) {
      _.forEach(a, function (v) {
        _.forEach(v, function (i) {
          list.push(i);
        });
      });
    });
  });
  setStorage('products', uniq(list), 'local');
  return uniq(list);
}

function groupProducts(products) {
  const viewOrder = [
    'Pouches',
    'Cans',
    'Tasty Slices',
    'Tasty Chunks',
    'Smooth',
    'Trays',
    'Dry Food',
    'Boxes',
    'Taster Boxes',
    'Tape',
    'Treat',
    'Flyers'
  ];
  let productsList = [];
  products.forEach(product => {
    let prod = skuToProduct(product.sku);
    prod.quantity = product.quantity;
    productsList.push(prod);
  });

  productsList.forEach(item => {
    item.displayType = formatFoodTypeName(item.foodtype);
  });
  productsList = groupBy('displayType', productsList);

  let obj = {};

  viewOrder.forEach(g => {
    if (productsList[g]) {
      obj[g] = productsList[g];
    }
  });
  productsList = obj;

  return productsList;
}

function formatFoodTypeName(type) {
  let foundType = find(['key', type], foodTypes);

  return foundType.display;
}

function skuToProduct(sku) {
  const products = store.state.products.products;
  return find(['sku', sku], products);
}

function getQuantity(increments, quantity, type) {
  const total = increments * quantity;
  if (type === 'dry') {
    return 'x ' + total / increments;
  } else {
    return 'x ' + total;
  }
}

function dateBefore(date) {
  return moment(date).endOf('day').isBefore(moment());
}

function toggleModal() {
  store.state.core.modalVisible = !store.state.core.modalVisible;
}

function sanitizeApiUrl(url) {
  return replace('api/', '', url);
}

function calculateWeight(products, taster, noFormat) {
  let weight = 0;
  const totals = [];

  products.forEach(i => {
    if (i.active) {
      i.products.forEach(p => {
        if (find(['sku', p.sku], totals)) {
          const sku = find(['sku', p.sku], totals);
          sku.total += p.total;
        } else {
          totals.push(cloneDeep(p));
        }
      });
    }
  });

  totals.forEach(t => {
    if (includes('_PCH_', t.sku)) {
      weight += t.total * 70;
    }
    if (includes('_TRA_', t.sku)) {
      weight += t.total * 85;
    }
    if (includes('_CAN_', t.sku)) {
      weight += t.total * 75;
    }
    if (includes('_BAG_', t.sku)) {
      weight += t.total;
    }
  });
  if (!noFormat) {
    if (weight < 1000) {
      return Math.ceil(weight) + 'g';
    } else {
      return Math.ceil(weight / 1000) + 'kg';
    }
  } else {
    return Math.ceil(weight / 1000);
  }
}

function setStorage(key, value, type) {
  if (type === 'local') {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
}
function getStorage(key, type) {
  if (type === 'local') {
    return JSON.parse(localStorage.getItem(key));
  } else {
    return JSON.parse(sessionStorage.getItem(key));
  }
}
function removeStorage(key, type) {
  if (type === 'local') {
    return localStorage.removeItem(key);
  } else {
    return sessionStorage.removeItem(key);
  }
}

function createKey(type, length) {
  let text = '';
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

  for (let i = 0; i < length; i++) {
    text += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return type + '-' + text;
}

function getPercentageOf(total, val) {
  const value = (val / total) * 100;
  return isNaN(value) ? 0 : value;
}
function getPercentageFrom(total, val) {
  const value = (val / total) * 100;
  return isNaN(value) ? 100 : 100 - value;
}
function getPercentageDiff(total, val) {
  var decreaseValue = total - val;

  const value = (decreaseValue / total) * 100;

  return value;
}

function getDays(date) {
  return moment(date).fromNow();
}

function stringToBoolean(string) {
  return string === 'true';
}

function isJSON(value) {
  try {
    const json = JSON.parse(value);
    if (json) {
      return true;
    }
  } catch (e) {
    return false;
  }
}

function getSubscriptionAmounts(cats) {
  const totals = { pouches: 0, trays: 0, dry: 0 };
  const activeCats = cats.filter(c => c.active);
  activeCats.forEach(ac => {
    ac.products.forEach(p => {
      if (includes('_PCH_', p.sku)) {
        totals.pouches += p.total;
      }
      if (includes('_TRA_', p.sku)) {
        totals.trays += p.total;
      }
      if (includes('_BAG_', p.sku)) {
        totals.dry += p.total / p.increments;
      }
    });
  });

  totals.totalWet = totals.pouches + totals.trays;
  totals.totalDry = totals.dry;

  return totals;
}

function convertTimestamp(timestamp) {
  return new Date(timestamp);
}

function round(value, step) {
  step || (step = 1.0);
  var inv = 1.0 / step;

  return Math.round(value * inv) / inv;
}

function decimalPlaces(value, precision) {
  return Number(Number(value).toFixed(precision));
}

function getDisplayCost(cost, minus, display) {
  if (display) {
    const pounds = (cost - minus) / 100;
    return `£${pounds.toLocaleString(undefined, {
      minmumSignificantDigits: 2,
      minimumFractionDigits: 2
    })}`;
  } else {
    return cost - minus;
  }
}

function getAmounts(type, subscription) {
  // get the type amount from all the active cats in the subscription
  const activeCats = subscription.cats.filter(c => c.active);
  let amount = 0;
  activeCats.forEach(ac => {
    ac.products.forEach(p => {
      const product = skuToProduct(p.sku);
      if (includes('W_', p.sku)) {
        amount += p.quantity * product.increments;
      }
    });
  });
  return amount;
}

function getProductsFromCats(order) {
  let products = [];

  // for each cat items ad the prduct sku and quantity to the products array
  order.cats.forEach(cat => {
    cat.items.forEach(item => {
      item.quantity = item.isDryFood ? item.quantity / 800 : item.quantity;

      const product = find(['sku', item.sku], products);
      if (product) {
        product.quantity += item.quantity;
        return;
      } else {
        products.push(item);
      }
    });
  });

  order.samples.forEach(sample => {
    products.push({ ...sample.product, ...{ quantity: sample.quantity } });
  });

  return products;
}
function getTastersFromCats(order) {
  const counts = {
    adult: 0,
    kitten: 0
  };
  const tasters = [];
  order.cats.forEach(c => {
    c.isKitten ? counts.kitten++ : counts.adult++;
  });
  if (counts.adult !== 0) {
    tasters.push({
      sku: 'T_TST_ADT',
      name: 'Taster Box',
      quantity: counts.adult
    });
  }
  if (counts.kitten !== 0) {
    tasters.push({
      sku: 'T_TST_KIT',
      name: 'Taster Box (Kitten)',
      quantity: counts.kitten
    });
  }

  return tasters;
}
function getImage(sku) {
  if (
    includes('W_PCH', sku) &&
    includes(sku, ['W_PCH_SLM_TSL', 'W_PCH_CHK_TSL'])
  ) {
    return 'W_PCH';
  }
  if (includes('D_BAG', sku)) {
    return 'D_BAG';
  }
  if (includes('T_TST', sku)) {
    return 'T_TST';
  }
  return sku;
}

function formatCreateOrder(payload, taster) {
  const order = payload;

  const products = [];
  order.forEach(p => {
    products.push({
      sku: taster ? [p.sku] : p.sku,
      quantity: p.isDryFood ? p.quantity * 800 : p.quantity
    });
  });

  return products;
}

function formatCoupons(payload) {
  const coupons = {};
  payload.forEach(c => {
    if (coupons[c.type]) {
      coupons[c.type]++;
    } else {
      coupons[c.type] = 1;
    }
  });

  // check if other is the only coupon with 1 and add a key to the object
  if (coupons.other === 1 && Object.keys(coupons).length === 1) {
    coupons.onlyDelivery = true;
  }

  return coupons;
}

function formatDate(date, format) {
  const fm = format || 'DD/MM/YYYY hh:mm';
  return moment(date).format(fm);
}

export default {
  getUrlParameter,
  setUrlParameter,
  flattenProducts,
  skuToProduct,
  sanitizeApiUrl,
  groupProducts,
  formatFoodTypeName,
  getQuantity,
  toggleModal,
  dateBefore,
  calculateWeight,
  setStorage,
  getStorage,
  createKey,
  getPercentageOf,
  getPercentageFrom,
  getPercentageDiff,
  getDays,
  stringToBoolean,
  getSubscriptionAmounts,
  isJSON,
  convertTimestamp,
  round,
  decimalPlaces,
  getDisplayCost,
  getAmounts,
  getProductsFromCats,
  getTastersFromCats,
  getImage,
  formatCreateOrder,
  formatCoupons,
  removeStorage,
  formatDate,
  removeUrlParameter
};
