import http from '@/http';

const stateObj = {
  products: null,
  productGroups: null,
  activeProduct: {}
};

const mutations = {
  set_products(state, payload) {
    state.productGroups = payload;
    state.products = payload;
    // state.products.push({
    //   sku: 'T_TST_KIT',
    //   name: 'Taster Box (KITTEN)',
    //   foodtype: 'taster'
    // });
    // state.products.push({
    //   sku: 'T_TST_ADT',
    //   name: 'Taster Box',
    //   foodtype: 'taster'
    // });
  },
  set_active_product(state, payload) {
    state.activeProduct = payload;
  }
};
const actions = {
  getProduct({ state }, payload) {
    return new Promise((resolve, reject) => {
      const product = state.products.find(p => p.sku === payload.sku);

      if (product) {
        resolve(product);
      } else {
        reject('could not find product');
      }
    });
  },
  getProductProfile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http
        .get(`packers/products/${payload.id}`)
        .then(response => {
          commit('set_active_product', response.data.content[0]);
          resolve(response.data.content[0]);
        })
        .catch(err => {
          reject('could not find product');
          throw Error(err.response.data.error.message);
        });
    });
  },
  getProducts({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get('packers/products')
        .then(response => {
          commit('set_products', response.data.content);
          resolve(response);
        })
        .catch(err => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  },
  discontinueProduct(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .put(`packers/product/${payload.id}/discontinued`, {
          discontinued: true
        })
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  },
  addProduct(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .post(`packers/products`, payload)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  },
  removeFood(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .delete(`packers/removefood`, { data: payload.data })
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  },
  updateProduct(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .put(
          `packers/products/${payload.id}${
            payload.type ? '/' + payload.type : ''
          }`,
          payload.data
        )
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  }
};

const getters = {
  getProducts: state => {
    return state.products;
  },
  getFoodProducts: state => {
    return state.products.filter(p => p.foodcategory !== 'misc');
  },
  getTasterProducts: state => {
    return state.products.filter(p => p.foodtype === 'taster');
  },
  getActiveProductGetter: state => {
    return state.activeProduct;
  }
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
