<template>
  <div>
    <div v-if="currentUser" class="nav-actions">
      <avatar size="md" :email="currentUser.name" />
    </div>
    <div ref="actions" class="w-32 flex">
      <template>
        <router-link
          :to="{ name: 'logout' }"
          class="hover:bg-gray-100 w-full py-1"
          >Logout</router-link
        >
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Avatar from '@/components/common/ui/Avatar';
import tippy from 'tippy.js';
export default {
  components: {
    Avatar
  },
  data() {
    return {
      tippyActions: null
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    })
  },
  mounted() {
    this.tippyActions = tippy('.nav-actions', {
      placement: 'right',
      interactive: true,
      content: this.$refs.actions,
      allowHTML: true,
      theme: 'light',
      arrow: false,
      delay: [400, 0]
    });
  }
};
</script>

<style lang="css" scoped></style>
