import Vue from 'vue';
import moment from 'moment';
import { startCase, capitalize, toNumber } from 'lodash/fp';
import { formatDistanceToNowStrict } from 'date-fns';
Vue.filter('timeago', function (value) {
  return formatDistanceToNowStrict(new Date(value), { addSuffix: true });
});
Vue.filter('timeto', function (value) {
  return moment().to(value);
});
Vue.filter('startCase', function (value) {
  return startCase(value);
});
Vue.filter('capitalize', function (value) {
  return capitalize(value);
});
Vue.filter('numberFormat', function (value) {
  return new Intl.NumberFormat().format(value);
});
Vue.filter('ordinal', function (value) {
  return moment.localeData().ordinal(value);
});
Vue.filter('formatKg', function (value) {
  const val = toNumber(value);

  if (val < 1000) {
    return val + 'g';
  } else {
    return val / 1000 + 'kg';
  }
});

Vue.filter('formatDate', function (value, format, unix) {
  const date = unix ? moment.unix(value / 1000) : moment(value);

  if (format) {
    return date.format(format);
  } else {
    return date.format('MM/DD/YYYY hh:mm');
  }
});

Vue.filter('formatPounds', function (value) {
  const pounds = value / 100;
  return `£${pounds.toFixed(2).toLocaleString(undefined, {
    minmumSignificantDigits: 2
  })}`;
});
