import utils from '@/utils';
const stateObj = {
  userSettings: utils.getStorage('userSettings', 'local') || {}
};

const mutations = {
  set_user_settings(state, payload) {
    state.userSettings = payload;
    utils.setStorage('userSettings', state.userSettings, 'local');
  }
};
const actions = {};

const getters = {
  userSettingsGetter: state => {
    return state.userSettings;
  }
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
