<template>
  <div
    class="sm:h-full border-b sm:border-r border-gray-200 bg-gray-100 sm:px-0 sm:py-2 flex flex-row sm:flex-col items-stretch"
  >
    <div
      class="flex flex-row w-full items-stretch sm:w-auto sm:flex-col sm:h-full text-center relative"
    >
      <div
        class="flex flex-grow overflow-x-auto h-full flex-row sm:flex-col relative"
      >
        <router-link
          exact
          :to="{ name: 'dashboard' }"
          data-tippy-content="Dashboard"
          class="nav-item"
        >
          <font-awesome-icon :icon="['fal', 'layer-group']" class="text-2xl"
        /></router-link>
        <router-link
          :to="{ name: 'userList' }"
          class="nav-item"
          data-tippy-content="Users"
          v-show="isAdmin"
          ><font-awesome-icon :icon="['fal', 'user-group']" class="text-2xl"
        /></router-link>
        <router-link
          :to="{ name: 'products' }"
          class="nav-item"
          data-tippy-content="Products"
          v-show="isAdmin && !isCustomerService"
          ><font-awesome-icon :icon="['fal', 'barcode-read']" class="text-2xl"
        /></router-link>
        <!-- 
        <router-link
          :to="{ name: 'stock' }"
          class="nav-item"
          data-tippy-content="Stock"
          v-show="isAdmin"
          ><font-awesome-icon :icon="['fal', 'robot']" class="text-2xl"
        /></router-link> -->

        <router-link
          :to="{ name: 'packing' }"
          data-tippy-content="Packing List"
          class="nav-item relative"
          ><font-awesome-icon
            :icon="['fal', 'boxes-stacked']"
            class="text-2xl" />
          <div class="absolute top-2 sm:top-1 right-2">
            <badge-counter :count="packingCount" /></div
        ></router-link>
        <router-link
          :to="{ name: 'shipping' }"
          data-tippy-content="Shipping List"
          class="nav-item relative"
          ><font-awesome-icon
            :icon="['fal', 'truck-ramp-box']"
            class="text-2xl" />
          <div class="absolute top-2 sm:top-1 right-2">
            <badge-counter :count="shippingCount" /></div
        ></router-link>
        <router-link
          :to="{ name: 'churn' }"
          class="nav-item relative"
          data-tippy-content="Churn"
          v-show="isAdmin"
          ><font-awesome-icon
            :icon="['fal', 'face-thinking']"
            class="text-2xl" />
          <div class="absolute top-2 sm:top-1 right-2">
            <badge-counter :count="churnCount" /></div
        ></router-link>
        <router-link
          :to="{ name: 'retention-stats' }"
          class="nav-item"
          data-tippy-content="Reporting"
          v-show="isAdmin && !isCustomerService"
          ><font-awesome-icon :icon="['fal', 'chart-mixed']" class="text-2xl"
        /></router-link>
        <router-link
          :to="{ name: 'cats' }"
          class="nav-item"
          data-tippy-content="Cats"
          v-show="isAdmin"
          ><font-awesome-icon :icon="['fal', 'shield-cat']" class="text-2xl"
        /></router-link>
      </div>
      <div
        class="flex items-center border-l sm:border-none flex-row sm:flex-col justify-center py-2 pr-4 sm:px-0"
      >
        <router-link
          :to="{ name: 'settings' }"
          class="nav-item"
          data-tippy-content="Settings"
          ><font-awesome-icon :icon="['fal', 'wrench']" class="text-2xl"
        /></router-link>
        <userActions class="sm:mt-4" :user="currentUser" v-if="currentUser" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BadgeCounter from '@/components/common/ui/BadgeCounter';
import UserActions from '@/components/common/ui/UserActions';

import tippy from 'tippy.js';
export default {
  components: {
    BadgeCounter,
    UserActions
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      packingCount: 'getPackingCount',
      shippingCount: 'getShippingCount',
      churnCount: 'churnCountGetter',
      isAdmin: 'isAdmin',
      isCustomerService: 'isCustomerService'
    })
  },
  mounted() {
    this.$nextTick(() => {
      tippy('.nav-item', {
        placement: 'right',
        arrow: false,
        delay: [400, 0],
        touch: 'hold'
      });
    });
  }
};
</script>

<style lang="scss" scoped>
.nav-item {
  @apply flex justify-center items-center  h-full sm:h-12  w-16   border-b-2 sm:border-b-0 sm:border-r-2 text-gray-500 border-transparent leading-normal flex-none;

  &.router-link-active {
    @apply text-indigo-500 border-indigo-500;
  }
}
</style>
