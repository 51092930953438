import http from '@/http';
import utils from '@/utils';
const stateObj = {
  reviews: [],
  reviewAllowance: 0,
  maxPerMonth: 100
};

const mutations = {
  set_reviews(state, payload) {
    state.reviews = payload.emails;
  },
  set_allowance(state, payload) {
    state.reviewAllowance = payload.sentThisMonth;
  }
};
const actions = {
  getReviews({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get(utils.sanitizeApiUrl(`api/packers/emails`))
        .then(resp => {
          commit('set_reviews', resp.data.content[0]);
          commit('set_allowance', resp.data.content[0]);
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  sendReview(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .post(utils.sanitizeApiUrl(`api/packers/users/${payload}/send_email`))
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  flagReview(_, payload) {
    return new Promise((resolve, reject) => {
      console.log(payload);
      http
        .put(utils.sanitizeApiUrl(`api/packers/trustpilot/${payload.uid}`), {
          hold: payload.data
        })
        .then(resp => {
          console.log('good');
          resolve(resp);
        })
        .catch(err => {
          console.log('bad');
          reject(err);
        });
    });
  },
  removeReview(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .post(
          utils.sanitizeApiUrl(`api/packers/users/${payload}/send_email`),
          null,

          {
            params: { deleteEmail: true }
          }
        )
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const getters = {
  reviewsGetter: state => {
    return state.reviews;
  },
  reviewAllowanceGetter: state => {
    return state.reviewAllowance;
  },
  reviewMaxGetter: state => {
    return state.maxPerMonth;
  }
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
