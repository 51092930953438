const stateObj = {
  emails: {
    'subscription-invoice-renewal': 'Subscription renewal',
    'subscription-invoice': 'Subscription confirmation',
    'sample-box-invoice': 'Taster Box confirmation',
    'trialpack-email': 'Subscription renewal reminder',
    'trialpack-email-endssoon': 'Subscription renewal reminder',
    'retention-email-5': 'Retention (Has it arrived)',
    'retention-email-6': 'Retention (Lets do this)',
    'referral-email-3': 'Refferal (Introduce refferals)',
    'prepurchase-email-1': 'Auto-Sub (Adjust to suit you)',
    'prepurchase-email-2': 'Auto-Sub (Did you know...)',
    'prepurchase-email-3':
      'Auto-Sub (We’ll be preparing your next box soon...)',
    'prepurchase-email-4': 'Auto-Sub (We’re about to send your first box)',
    'prepurchase-email-5': 'Auto-Sub (We’ve sent out your first box)',
    'referral-bonus': 'Referral bonus',
    'paymenttomorow-paymenttomorowpaused': 'Subscription adjust reminder',
    'trustpilot-1': 'Trustpilot Review',
    tips_for_food_day_3: '14 Day Trial Day 3',
    worth_knowing_day_5: '14 Day Trial Day 5',
    best_order_day_6: '14 Day Trial Day 6',
    referral_intro: 'Referral',
    review_email: 'Review',
    '48_before_first_box': '14 Day Trial',
    '48_before_sub': 'Subscription reminder',
    payment_error: 'Payment error'
  }
};

const mutations = {};
const actions = {};

const getters = {
  langGetter: state => state
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
