import http from '@/http';
import utils from '@/utils';

const stateObj = {};

const mutations = {};
const actions = {
  getCoupons() {
    return new Promise((resolve, reject) => {
      http
        .get(utils.sanitizeApiUrl(`/api/packers/coupons`))
        .then(resp => {
          console.log('got coupns');
          resolve(resp.data.content[0].coupons);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  addCoupon(_, payload) {
    return new Promise((resolve, reject) => {
      console.log('add coupon', payload);
      http
        .post(
          utils.sanitizeApiUrl(
            `/api/packers/user/${payload.uuid}/subscription/${payload.subid}`
          ),
          { coupon: payload.coupon }
        )
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  removeCoupon(_, payload) {
    return new Promise((resolve, reject) => {
      console.log('remove coupon', payload);
      http
        .delete(
          utils.sanitizeApiUrl(
            `/api/packers/user/${payload.uuid}/subscription/${payload.subid}`
          ),
          {
            data: { coupon: payload.coupon }
          }
        )
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const getters = {};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
