import http from '@/http';

import moment from 'moment';

import {
  find,
  findIndex,
  cloneDeep,
  orderBy,
  includes,
  filter,
  isObject,
  isEmpty
} from 'lodash/fp';
import utils from '@/utils';
import { checklist } from '@/const';
import qz from 'qz-tray';

const stateObj = {
  user: JSON.parse(sessionStorage.getItem('user')) || '',
  users: null,
  isPackingMachine: utils.getStorage('isPackingMachine', 'local'),
  isTestingMachine: utils.getStorage('isTestingMachine', 'local'),
  packingList: null,
  packingChecklist: {},
  packingCount: 0,
  shippingCount: 0,
  shippingList: null,
  today: moment().endOf('day'),
  currentUserProfile: {
    user: null,
    subscription: null,
    history: null,
    activity: null,
    links: null,
    referrals: null
  },
  currentPackingItem: {},
  keys: {
    cert: utils.getStorage('cert', 'local') || null,
    private: utils.getStorage('private', 'local') || null
  },
  pagination: {
    current: 1
  },
  modalVisible: false,
  qz: {
    status: 'inactive',
    printers: [],
    defaultLabelPrinter:
      utils.getStorage('defaultLabelPrinter', 'local') || null
  },
  subValidation: {
    isValid: true,
    message: '',
    isWetMax: false,
    isDryMax: false
  },
  dryIncrement: 800,
  isProduction: process.env.NODE_ENV === 'production'
};

const mutations = {
  user_success(state, user) {
    state.user = user;
  },
  set_is_packing_machine(state, payload) {
    state.isPackingMachine = payload;
    utils.setStorage('isPackingMachine', payload, 'local');
  },
  set_is_testing_machine(state, payload) {
    state.isTestingMachine = payload;
    utils.setStorage('isTestingMachine', payload, 'local');
  },
  set_current_packing_item(state, payload) {
    state.currentPackingItem = payload;
  },
  update_packing_item_key(state, payload) {
    if (isObject(payload.payload)) {
      state.currentPackingItem[payload.key] = {
        ...state.currentPackingItem[payload.key],
        ...payload.payload
      };
    } else {
      state.currentPackingItem[payload.key] = payload.payload;
    }
  },

  set_products(state, payload) {
    state.productGroups = payload;
    state.products = utils.flattenProducts(payload);
    // state.products.push({
    //   sku: 'T_TST_KIT',
    //   name: 'Taster Box (Kitten)',
    //   foodtype: 'taster'
    // });
    // state.products.push({
    //   sku: 'T_TST_ADT',
    //   name: 'Taster Box',
    //   foodtype: 'taster'
    // });
  },
  update_minimum_sub(state, payload) {
    state.subValidation = payload;
  },
  set_packing_checklist(state, payload) {
    state.packingChecklist = payload;
  },
  set_packing_count(state, payload) {
    state.packingCount = payload;
  },
  set_packing_list(state, payload) {
    state.packingList = payload;
  },
  set_shipping_count(state, payload) {
    state.shippingCount = payload;
  },
  set_shipping_list(state, payload) {
    state.shippingList = payload;
  },
  update_packing_checklist(state, payload) {
    const index = findIndex(['key', payload.type], state.packingChecklist);
    state.packingChecklist[index].isCompleted = payload.value;
  },
  reset_packing_checklist(state) {
    state.packingChecklist = cloneDeep(checklist);
  },
  set_users(state, payload) {
    state.users = payload;
    state.pagination.current = payload.links.currentpage;
  },
  set_user_profile(state, payload) {
    if (payload.type === 'user') {
      Object.entries(payload.value.settings).forEach(([key, value]) => {
        if (isObject(value)) {
          payload.value.settings[key] = value;
        } else if (utils.isJSON(value)) {
          payload.value.settings[key] = JSON.parse(value);
        } else {
          if (value === 'true' || value === 'false') {
            payload.value.settings[key] = utils.stringToBoolean(value);
          } else {
            payload.value.settings[key] = value;
          }
        }
      });
    }
    state.currentUserProfile[payload.type] = payload.value;
  },
  update_user_settings(state, payload) {
    const settings = payload;

    Object.entries(settings).forEach(([key, value]) => {
      if (utils.isJSON(value)) {
        settings[key] = JSON.parse(value);
      } else {
        settings[key] = value;
      }
    });
    state.currentUserProfile.user.settings = {
      ...state.currentUserProfile.user.settings,
      ...settings
    };
  },
  reset_user_profile(state) {
    state.currentUserProfile.user = null;
    state.currentUserProfile.subscription = null;
    state.currentUserProfile.history = null;
  },
  set_keys(state, payload) {
    state.keys[payload.type] = payload.payload;
    utils.setStorage(payload.type, payload.payload, 'local');
  },
  set_qz_status(state, payload) {
    state.qz.status = payload;
  },
  set_default_printer(state, payload) {
    utils.setStorage('defaultLabelPrinter', payload.name, 'local');
    state.qz[payload.type] = payload.name;
  }
};
const actions = {
  getLoggedInUser({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get('/packers/me')
        .then(response => {
          const user = response.data.content[0];
          commit('user_success', user);
          sessionStorage.setItem('user', JSON.stringify(user));
          resolve(user);
        })
        .catch(error => {
          reject(error);
        });

      commit('check_scopes');
    });
  },
  getKey({ commit }, type) {
    return new Promise((resolve, reject) => {
      let url = '/packers/print_cert';
      if (includes('private', type)) {
        url = '/packers/print_cert2';
      }
      if (!utils.getStorage(type, 'local')) {
        http
          .get(url)
          .then(response => {
            commit('set_keys', { type, payload: response.data });
            resolve(response.data);
          })
          .catch(() => {
            reject(`Could not get ${type} key`);
          });
      } else {
        resolve(utils.getStorage(type, 'local'));
      }
    });
  },
  getUsers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let url = '/packers/users';
      if (payload) {
        url += `?&page=${payload}`;
      }
      http
        .get(url)
        .then(response => {
          commit('set_users', response.data);
          resolve(response.data.content);
        })
        .catch(() => {
          reject('could not find users');
        });
    });
  },
  searchUsers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let url = '/packers/user/search/';
      if (payload) {
        url += `?search=${payload}`;
      }
      http
        .get(url)
        .then(response => {
          commit('set_users', response.data);
          resolve(response.data.content);
        })
        .catch(() => {
          reject('could not find users');
        });
    });
  },
  getUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http
        .get(`/packers/user/${payload}`)
        .then(response => {
          const user = response.data.content[0];

          commit('set_user_profile', { type: 'user', value: user });
          commit('set_user_profile', {
            type: 'links',
            value: response.data.links
          });
          commit('update_user_settings', {
            marketing: JSON.parse(user.gdprMarketingAccepted)
          });

          resolve(user);
        })
        .catch(() => {
          reject('could not find user');
        });
    });
  },
  getUserHistory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http
        .get(`/packers/user/${payload}/history`)
        .then(response => {
          commit('set_user_profile', {
            type: 'history',
            value: response.data.content
          });
          resolve(response.data.content);
        })
        .catch(() => {
          reject('could not find user');
        });
    });
  },
  getUserActivity({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http
        .get(`/packers/user/${payload}/log`)
        .then(response => {
          commit('set_user_profile', {
            type: 'activity',
            value: response.data.content
          });
          resolve(response.data.content);
        })
        .catch(() => {
          reject('could not find referalls');
        });
    });
  },
  getUserReferrals({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http
        .get(`/packers/users/${payload}/referrals`)
        .then(response => {
          commit('set_user_profile', {
            type: 'referrals',
            value: response.data.content[0]
          });
          resolve(response.data.content);
        })
        .catch(() => {
          reject('could not find activity');
        });
    });
  },
  getUserCurrentSubscription({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http
        .get(`/packers/user/${payload}/current`)
        .then(response => {
          commit('set_user_profile', {
            type: 'subscription',
            value: response.data.content[0]
          });
          console.log('sub content', response.data.content[0]);
          commit('set_user_profile', {
            type: 'coupons',
            value: utils.formatCoupons(
              response.data.content[0].subscription.coupons
            )
          });

          resolve(response.data.content[0]);
        })
        .catch(() => {
          reject('could not find user');
        });
    });
  },

  getPackingList({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      http
        .get('/packers/deliveries/needpacking')
        .then(response => {
          const packingList = response.data.content;
          commit('set_packing_list', packingList);
          const filteredPacking = filter(function(o) {
            return (
              moment(o.dispatchAt).isBefore(state.today) ||
              moment(o.dispatchAt).isSame(state.today, 'd')
            );
          }, packingList);
          commit('set_packing_count', filteredPacking.length);
          dispatch('getShippingList');
          resolve(packingList);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getPackingItem({ commit }, id) {
    return new Promise((resolve, reject) => {
      http
        .get(`/packers/deliveries/${id}`)
        .then(response => {
          commit('set_current_packing_item', response.data.content[0]);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updatePackingAddress(_, payload) {
    return new Promise((resolve, reject) => {
      const data = payload.data;
      data.postcode = data.postcode.toUpperCase();
      http
        .put(
          utils.sanitizeApiUrl(`api/packers/deliveries/${payload.uid}/address`),
          data
        )
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err.response.data.message);
        });
    });
  },
  markAsPacked({ dispatch }, url) {
    return new Promise((resolve, reject) => {
      http
        .post(utils.sanitizeApiUrl(url))
        .then(response => {
          dispatch('getPackingList');
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  print({ state }, payload) {
    return new Promise((resolve, reject) => {
      if (payload.data) {
        const formatted = payload.data;

        let data;
        if (formatted instanceof Blob) {
          var reader = new FileReader();
          reader.readAsDataURL(formatted);
          reader.onloadend = function() {
            let base64String = reader.result;

            var config = qz.configs.create(state.qz.defaultLabelPrinter.name, {
              // size: { width: 5, height: 5 },
              units: 'in',

              margins: {
                top: 0,
                right: 0,
                bottom: 0,
                left: payload.type === 'amazon' ? 0.12 : 0.14
              },
              size: { width: 4, height: 6 },
              scaleContent: false,
              ignoreTransparency: true,
              rasterize: false
            });

            data = [
              {
                type: 'pixel',
                format: 'pdf',
                flavor: 'base64',
                data: base64String.split(',')[1]
              }
            ];

            qz.print(config, data).catch(function(e) {
              reject(e);
            });
            resolve();
          };
        } else {
          if (payload.type === 'dpd') {
            let format = formatted;
            format = format.replaceAll('Q822,24', 'Q1218,32');
            format = format.replaceAll('R40,0', 'R54,30');

            data = [format];
          } else if (payload.type === 'yodel') {
            let format = formatted;

            data = [format];
          } else {
            data = [formatted];
          }

          const config = qz.configs.create(state.qz.defaultLabelPrinter.name, {
            altPrinting: true,
            units: 'in',
            size: { width: 4, height: 6 }
          });

          qz.print(config, data).catch(function(e) {
            reject(e);
          });
          resolve();
        }
      } else {
        reject('No label returned from the API');
      }
    });
  },
  getShippingList({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get('/packers/deliveries/needshipping')
        .then(response => {
          let shippingList = response.data.content;
          shippingList = orderBy(['dispatchid'], ['desc'], shippingList);
          shippingList.forEach(i => {
            Object.assign({}, i, { isSelected: false });
          });
          commit('set_shipping_list', shippingList);
          commit('set_shipping_count', shippingList.length);
          resolve(shippingList);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  markAsShipped({ dispatch }, url) {
    return new Promise((resolve, reject) => {
      http
        .post(utils.sanitizeApiUrl(url))
        .then(response => {
          dispatch('getShippingList');
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  packingAction(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .delete(utils.sanitizeApiUrl(payload.url), {
          data: { reason: payload.reason }
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  createPackingChecklist({ commit }, packingList) {
    return new Promise(resolve => {
      const clist = cloneDeep(checklist);
      let disabled = [];
      if (packingList.isSample) {
        disabled = disabled.concat(['I_CS', 'I_FL', 'I_PB']);
      } else {
        disabled = disabled.concat(['I_TB', 'I_CLF']);
      }
      if (!packingList.firstMonth && !packingList.isSample) {
        disabled = disabled.concat(['I_WC', 'I_FL']);
      }
      if (isEmpty(packingList.bonus.products)) {
        disabled = disabled.concat(['I_BO', 'I_BF']);
      }

      clist.forEach(c => {
        if (includes(c.key, disabled)) {
          c.isRequired = false;
        }
      });
      // // HACK FOR KATE - SHORTTERM UNTILL GET MORE packersShip
      // if (packingList.isSample && packingList.cats.length === 1) {
      //   find(['key', 'I_PL'], clist).isCompleted = true;
      //   find(['key', 'I_WC'], clist).isCompleted = true;
      // }
      // //

      find(['key', 'I_PL'], clist).courier = '';
      // find(['key', 'I_PL'], clist).userCan =
      //   packingList.isSample && packingList.cats.length === 1;

      commit('set_packing_checklist', clist);
      resolve();
    });
  },
  getShippingLabel(_, item) {
    return new Promise((resolve, reject) => {
      http
        .get(
          utils.sanitizeApiUrl(item.link),

          {
            params: {
              weight: item.weight,
              parcels: item.noOfParcels,
              type: item.service.type,
              parceltype: item.service.key
            },
            noTimestamp: true,
            responseType: item.service.responseType || null
          }
        )
        .then(response => {
          resolve(response);
        })
        .catch(() => {
          reject('No label returned from the API');
        });
    });
  },
  removeShippingLabel(_, item) {
    return new Promise((resolve, reject) => {
      http
        .delete(utils.sanitizeApiUrl(item.link))
        .then(response => {
          resolve(response);
        })
        .catch(() => {
          reject('Could not remove label');
        });
    });
  },

  logout({ commit }) {
    return new Promise(resolve => {
      commit('logout');
      localStorage.removeItem('token');
      sessionStorage.removeItem('user');
      localStorage.removeItem('cats');
      delete http.defaults.headers.common['Authorization'];
      resolve();
    });
  }
};

const getters = {
  currentUser: state => state.user,
  getModalVisible: state => state.modalVisible,
  getUsers: state => {
    return state.users;
  },
  subValidation: state => state.subValidation,
  dryIncrementGetter: state => state.dryIncrement,
  getPackingChecklist: state => state.packingChecklist,
  getPackingList: state => state.packingList,
  getPackingCount: state => state.packingCount,
  getShippingCount: state => state.shippingCount,
  getShippingList: state => state.shippingList,
  getCurrentUserProfile: state => state.currentUserProfile,
  getCurrentUserProfileActivity: state => state.currentUserProfile.activity,
  getCurrentUserSubscription: state =>
    state.currentUserProfile.subscription.subscription,
  getPagination: state => state.pagination,
  getPrintKeys: state => state.keys,
  getQzStatus: state => state.qz.status,
  isPackingMachine: state => state.isPackingMachine,
  isTestingMachine: state => state.isTestingMachine,
  currentPackingItemGetter: state => state.currentPackingItem,
  currentPackingBoxGetter: state => {
    const boxes = state.currentPackingItem?.packaging?.products.filter(p =>
      p.sku.includes('_UNIT')
    );
    if (!boxes) return null;
    const totalBoxes = boxes.reduce((a, b) => a + b.total, 0);
    return { boxes: boxes, totalBoxes: totalBoxes };
  },
  isProductionGetter: state => state.isProduction
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
