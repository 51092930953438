import http from '@/http';
import utils from '@/utils';
const stateObj = {};

const mutations = {};
const actions = {
  createNote({ commit }, payload) {
    console.log(commit);
    return new Promise((resolve, reject) => {
      http
        .post(utils.sanitizeApiUrl(payload.endpoint), { data: payload.note })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getFutureNotes({ commit }, payload) {
    console.log(commit);
    return new Promise((resolve, reject) => {
      http
        .get(
          utils.sanitizeApiUrl(
            `/api/packers/users/${payload.uid}/userfuturenotes`
          )
        )
        .then(resp => {
          console.log(resp);
          resolve(resp.data.content);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  removeNote(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .delete(utils.sanitizeApiUrl(payload.endpoint))
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const getters = {};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
