<template>
  <transition
    enter-active-class="bounceIn"
    leave-active-class="bounceOut"
    mode="out-in"
  >
    <div
      v-if="count > 0"
      class="bg-indigo-400 font-semibold rounded-lg text-xs px-1.5 text-white flex
    items-center justify-center leading-none h-4"
    >
      {{ count }}
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true
    }
  }
};
</script>

<style lang="css" scoped></style>
