import Vue from 'vue';

Vue.directive('scroll-shadow', {
  inserted: function(el, binding) {
    const body = document.getElementById(
      binding?.value?.wrapper || 'content-wrapper'
    );
    let f = function(ev) {
      if (!binding?.value?.horizontal) {
        if (ev.target.scrollTop > 20) {
          el.classList.add('shadow-lg');
        } else {
          el.classList.remove('shadow-lg');
        }
      } else {
        if (ev.target.scrollLeft > 1) {
          el.classList.add('border-r');
        } else {
          el.classList.remove('border-r');
        }
      }
    };
    body.addEventListener('scroll', f);
  }
});
