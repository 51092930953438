import http from '@/http';
import { orderBy } from 'lodash/fp';
import moment from 'moment';

const stateObj = {
  coreStats: null,
  analyticsStats: null,
  stockStats: null
};

const mutations = {
  set_core_stats(state, payload) {
    state.coreStats = payload;
  },
  set_analytics_stats(state, payload) {
    state.analyticsStats = payload;
  },
  set_stock_stats(state, payload) {
    state.stockStats = payload;
  },
  set_stock_stat(state, payload) {
    payload.stats.forEach(s => {
      const product = state.stockStats.find(ss => ss.sku === s.sku);
      product[payload.key] = s.count;
    });
  }
};
const actions = {
  getCoreStats({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get('/packers/stats')
        .then(resp => {
          commit('set_core_stats', resp.data.content[0]);
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAnalyticsStats({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http
        .get('/packers/analytics', {
          params: {
            start: payload.start,
            end: payload.end
          }
        })
        .then(resp => {
          const order = orderBy(['date'], ['asc'], resp.data.content);
          if (!payload.local || !payload.singular) {
            commit('set_analytics_stats', order);
          }
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // getStockStats({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     http
  //       .get('/packers/stats2')
  //       .then(resp => {
  //         commit('set_stock_stats', resp.data.content);
  //         resolve(resp);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // },
  usersTrialsEndingList({ state }, payload) {
    return new Promise((resolve, reject) => {
      console.log(state);
      http
        .post('/packers/trialsending', payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getCharityStats({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const dates = {
        start: payload?.start || '2022-01-01',
        end: payload?.end || moment().format('YYYY-MM-DD')
      };
      http
        .get('/packers/removecharityfood', { params: dates })
        .then(resp => {
          const stats = resp.data.content;

          commit('set_stock_stat', { key: 'charityCount', stats });
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getWastageStats({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const dates = {
        start: payload?.start || '2022-01-01',
        end: payload?.end || moment().format('YYYY-MM-DD')
      };
      http
        .get('/packers/removefood', { params: dates })
        .then(resp => {
          const stats = resp.data.content;

          commit('set_stock_stat', { key: 'wastageCount', stats });
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getLatestSubOrders() {
    return new Promise((resolve, reject) => {
      http
        .get('/packers/statistics/newsubscribers')
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getTasterStats(_, params) {
    console.log(params);
    return new Promise((resolve, reject) => {
      http
        .get('/stats/cohort', { params })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const getters = {
  coreStatsGetter: state => state.coreStats,
  analyticsStatsGetter: state => state.analyticsStats,
  stockStatsGetter: state => state.stockStats
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
