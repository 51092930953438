import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'base'
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/Settings.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "auth" */ '@/views/auth/Login.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () =>
      import(/* webpackChunkName: "auth" */ '@/views/auth/Logout.vue')
  },
  {
    path: '/users',
    name: 'userList',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "users" */ '@/views/users/List.vue')
  },
  {
    path: '/users/:id/:tab',
    name: 'userProfile',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "users" */ '@/views/users/Profile.vue')
  },
  {
    path: '/products',
    name: 'products',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "products" */ '@/views/products/List.vue')
  },
  {
    path: '/stock',
    name: 'stock',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "products" */ '@/views/stock/Dashboard.vue')
  },
  {
    path: '/products/:id/:tab',
    name: 'productProfile',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "products" */ '@/views/products/Profile.vue')
  },
  {
    path: '/packing',
    name: 'packing',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "packing" */ '@/views/packing/List.vue')
  },
  {
    path: '/packing/:id',
    name: 'packingProfile',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName:  "packing" */ '@/views/packing/Profile.vue')
  },
  {
    path: '/shipping',
    name: 'shipping',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "packing" */ '@/views/shipping/List.vue')
  },
  {
    path: '/shipping/:id',
    name: 'shippingProfile',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName:  "packing" */ '@/views/packing/Profile.vue')
  },
  {
    path: '/reviews',
    name: 'reviews',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "reviews" */ '@/views/reviews/List.vue')
  },
  {
    path: '/churn',
    name: 'churn',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "churn" */ '@/views/churn/List.vue')
  },
  {
    path: '/cats',
    name: 'cats',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "churn" */ '@/views/Cats.vue')
  },
  {
    path: '/reporting',
    name: 'reporting',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "reporting" */ '@/views/reporting/Reporting.vue'
      ),
    children: [
      {
        name: 'retention-stats',
        path: '/',
        component: () =>
          import(
            /* webpackChunkName: "reporting-retention-stats" */ '@/views/reporting/RetentionStats.vue'
          )
      },
      {
        name: 'monthly-stock',
        path: 'monthly-stock',
        component: () =>
          import(
            /* webpackChunkName: "reporting-monthly-stock" */ '@/views/reporting/MonthlyStock.vue'
          )
      },
      {
        name: 'taster-stats',
        path: 'taster-stats',
        component: () =>
          import(
            /* webpackChunkName: "reporting-monthly-stock" */ '@/views/reporting/Tasters.vue'
          )
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }

  if (to.matched.some(record => record.meta.guest)) {
    if (!store.getters.isLoggedIn) {
      next();
      return;
    }

    next({ name: 'dashboard' });
  } else {
    next();
  }

  if (to.matched.some(record => record.name === 'base')) {
    if (store.getters.isLoggedIn) {
      next({ name: 'dashboard' });
    } else {
      next({ name: 'login' });
    }
  }
});

export default router;
