import http from '@/http';
import utils from '@/utils';
const stateObj = {};

const mutations = {};
const actions = {
  setPackingHold(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .put(
          utils.sanitizeApiUrl(`/api/packers/deliveries/${payload.id}/status`),
          { status: payload.status }
        )
        .then(resp => {
          resolve(resp.data.content[0]);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const getters = {};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
