import { orderBy } from 'lodash/fp';
const stateObj = {
  packingProgress: {
    total: 0,
    completed: 0,
    items: { subscriptions: [], tasters: [] },
    next: null
  }
};

const mutations = {
  set_packing_items(state, payload) {
    state.packingProgress.items = payload;
    state.packingProgress.total = payload.length;
  },
  set_next_item(state, payload) {
    state.packingProgress.next = payload;
  }
};
const actions = {
  setPackingProgress({ state }, payload) {
    state.packingProgress.items.tasters = [];
    state.packingProgress.items.subscriptions = [];
    payload.forEach(p => {
      p.isSample
        ? state.packingProgress.items.tasters.push(p)
        : state.packingProgress.items.subscriptions.push(p);
    });
    state.packingProgress.items.tasters = orderBy(
      ['isFirstMonth', 'dispatchid'],
      ['desc', 'asc'],
      state.packingProgress.items.tasters
    );
    state.packingProgress.items.subscriptions = orderBy(
      ['isFirstMonth', 'dispatchid'],
      ['desc', 'asc'],
      state.packingProgress.items.subscriptions
    );
  }
};

const getters = {
  packingProgressGetter: state => state.packingProgress
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
