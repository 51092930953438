import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import core from './modules/core';
import churn from './modules/churn';
import coupons from './modules/coupons';
import delivery from './modules/delivery';
import invoice from './modules/invoice';
import notes from './modules/notes';
import order from './modules/order';
import packers from './modules/packers';
import packing from './modules/packing';
import predictions from './modules/predictions';
import products from './modules/products';
import stock from './modules/stock';
import stats from './modules/stats';
import lang from './modules/lang';
import user from './modules/user';
import credit from './modules/credit';
import reviews from './modules/reviews';
import analytics from './modules/analytics';
import settings from './modules/settings';
import hold from './modules/hold';
import cats from './modules/cats';
import label from './modules/label';

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  modules: {
    auth,
    analytics,
    core,
    coupons,
    churn,
    delivery,
    invoice,
    credit,
    lang,
    notes,
    order,
    packers,
    packing,
    predictions,
    products,
    reviews,
    stock,
    stats,
    user,
    settings,
    hold,
    cats,
    label
  }
});
