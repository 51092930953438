import http from '@/http';
import utils from '@/utils';

const stateObj = {};

const mutations = {};
const actions = {
  createOrder(_, payload) {
    return new Promise((resolve, reject) => {
      const endpoint = payload.isTaster ? 'manualordertaster' : 'manualorder';
      if (payload.isTaster) {
        const tasters = [];
        payload.data.items.forEach(item => {
          // for each quantity push to the tasters array {sku}
          for (let i = 0; i < item.quantity; i++) {
            tasters.push({
              sku: item.sku
            });
          }
        });
        payload.data.items = tasters;
      }
      http
        .post(
          utils.sanitizeApiUrl(
            `/api/packers/users/${payload.uuid}/${endpoint}`
          ),
          payload.data
        )
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const getters = {};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
