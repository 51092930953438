import axios from "axios";
import store from "@/store";
import _ from "lodash";
import camelcaseKeys from "camelcase-keys";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

http.interceptors.response.use(
  function(response) {
    if (_.includes(response.headers["content-type"], "application/json")) {
      response.data = camelcaseKeys(response.data, {
        deep: true
      });
    }
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

http.interceptors.request.use(
  function(config) {
    config.params = config.params || {};
    if (!config.noTimestamp) {
      config.params["t"] = Date.now();
    }

    const token = store.state.auth.token;
    if (token) config.headers.Authorization = token;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default http;
