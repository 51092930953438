<template>
  <div></div>
</template>

<script>
import qz from "qz-tray";
import { KEYUTIL, KJUR, stob64, hextorstr } from "jsrsasign";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      printKeys: "getPrintKeys",
      isPackingMachine: "isPackingMachine"
    })
  },
  mounted() {
    const self = this;
    qz.security.setCertificatePromise(function(resolve) {
      resolve(self.printKeys.cert);
    });

    qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
    qz.security.setSignaturePromise(function(toSign) {
      return function(resolve, reject) {
        try {
          var pk = KEYUTIL.getKey(self.printKeys.private);
          var sig = new KJUR.crypto.Signature({ alg: "SHA512withRSA" }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
          sig.init(pk);
          sig.updateString(toSign);
          var hex = sig.sign();
          resolve(stob64(hextorstr(hex)));
        } catch (err) {
          console.error(err);
          reject(err);
        }
      };
    });
    if (this.isPackingMachine) {
      this.launchQZ();
    }
  },
  methods: {
    launchQZ() {
      if (!qz.websocket.isActive()) {
        window.location.assign("qz:launch");
        //Retry 5 times, pausing 1 second between each attempt
        this.startConnection({ retries: 5, delay: 1 });
      }
    },
    strip(key) {
      if (key.indexOf("-----") !== -1) {
        return key.split("-----")[2].replace(/\r?\n|\r/g, "");
      }
    },
    startConnection(config) {
      const self = this;
      if (!qz.websocket.isActive()) {
        self.$store.commit("set_qz_status", "connecting");
        qz.websocket
          .connect(config)
          .then(function() {
            console.log("we are connected");
            self.$store.commit("set_qz_status", "active");
          })
          .catch(function() {
            self.$store.commit("set_qz_status", "inactive");
          });
      } else {
        console.log("An active connection with QZ already exists.");
      }
    }
  }
};
</script>

<style lang="css" scoped></style>
