import http from '@/http';
import utils from '@/utils';
import { orderBy, first, last } from 'lodash/fp';
import {
  subMonths,
  format,
  lastDayOfMonth,
  getMonth,
  startOfMonth
} from 'date-fns';
const stateObj = {
  historicalAnalytics: utils.getStorage('historicalAnalytics', 'local'),
  historicalAnalytics6Months: utils.getStorage(
    'historicalAnalytics6Months',
    'local'
  ),
  analyticsHelpers: utils.getStorage('analyticsHelpers', 'local')
};

const compare = subMonths(new Date(), 2);
const start = format(lastDayOfMonth(compare), 'yyyy-MM-dd');

const end = format(startOfMonth(new Date()), 'yyyy-MM-dd');

const thisMonth = getMonth(new Date());
const mutations = {
  set_6_months_analytics_stats(state, payload) {
    state.historicalAnalytics6Months = payload;
  },
  set_historical_analytics_stats(state, payload) {
    const corePlus = last(payload);
    const comparePlus = payload[1];
    const core = payload[payload.length - 2];

    const compare = first(payload);

    state.historicalAnalytics = {
      lastChecked: getMonth(new Date()),
      core: {
        label: format(new Date(core?.date), 'MMMM'),
        date: new Date(core?.date),
        data: core.data
      },
      compare: {
        label: format(new Date(compare?.date), 'MMMM'),
        date: new Date(compare?.date),
        data: compare.data
      },
      corePlus: {
        label: format(new Date(corePlus?.date), 'MMMM'),
        date: new Date(corePlus?.date),
        data: corePlus.data
      },
      comparePlus: {
        label: format(new Date(comparePlus?.date), 'MMMM'),
        date: new Date(comparePlus?.date),
        data: comparePlus.data
      }
    };

    utils.setStorage('historicalAnalytics', state.historicalAnalytics, 'local');
  },
  set_analytics_helpers(state, payload) {
    state.analyticsHelpers = payload;
    utils.setStorage('analyticsHelpers', state.analyticsHelpers, 'local');
  }
};
const actions = {
  checkHistory(_, payload) {
    return new Promise(resolve => {
      if (thisMonth !== payload?.lastChecked) {
        resolve();
      }
    });
  },
  getHistoricalAnalytics({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http
        .get('/packers/analytics', {
          params: {
            start: payload?.start || start,
            end: payload?.end || end
          }
        })
        .then(resp => {
          const order = orderBy(['date'], ['asc'], resp.data.content);
          if (!payload?.start && !payload?.end) {
            commit('set_historical_analytics_stats', order);
          }

          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAnalyticsBulk(_, payload) {
    return new Promise((resolve, reject) => {
      const promises = [];

      payload?.dates.forEach(date => {
        promises.push(
          http.get('/packers/analytics', {
            params: {
              start: date,
              end: date
            }
          })
        );
      });

      Promise.all(promises)
        .then(resp => {
          const vals = [];

          resp.forEach(item => {
            const startDate = item.config.params.start;

            const data = {
              ...(item?.data?.content[0] || { date: startDate }),
              data: item?.data?.content[0]?.data[payload?.attribute] || []
            };

            vals.push(data);
          });

          resolve(vals);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAnalyticsHelpers({ commit }) {
    return new Promise((resolve, reject) => {
      console.log('getting helpers');
      http
        .get('/packers/analytics/description')
        .then(resp => {
          commit('set_analytics_helpers', resp.data.content[0]);
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const getters = {
  analyticsHistoryGetter: state => state.historicalAnalytics,
  analyticsHistory6MonthsGetter: state => state.historicalAnalytics6Months,
  analyticsHelpersGetter: state => state.analyticsHelpers
};
export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
