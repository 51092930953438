import http from '@/http';
import utils from '@/utils';

import { filter, includes, orderBy, findIndex } from 'lodash/fp';
const stateObj = {
  portalUsers: [],
  packers: []
};

const mutations = {
  set_packers(state, payload) {
    state.packers = filter(function (o) {
      return includes('packer', o.type);
    }, payload);
    state.packers = orderBy(['name'], ['asc'], state.packers);
  },
  set_portal_users(state, payload) {
    const filtered = payload.filter(p => !includes('removed', p.type));
    state.portalUsers = orderBy(['id'], ['asc'], filtered);
  },
  update_portal_user(state, payload) {
    const index = findIndex(['id', payload.id], state.portalUsers);
    state.portalUsers.splice(index, 1, payload);
    state.packers = filter(function (o) {
      return includes('packer', o.type);
    }, state.portalUsers);
  }
};
const actions = {
  getPortalUsers({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get(utils.sanitizeApiUrl(`/api/packers/packers`))
        .then(resp => {
          commit('set_portal_users', resp.data.content);
          commit('set_packers', resp.data.content);
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  togglePacker(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .put(
          utils.sanitizeApiUrl(
            `/api/packers/packer/${payload.id}/${
              !payload.status ? 'unassign' : 'reassign'
            }`
          )
        )
        .then(resp => {
          resolve(resp.data.content[0]);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  reassignPacker(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .put(
          utils.sanitizeApiUrl(
            `/api/packers/deliveries/${payload.deliveryId}/packer`
          ),
          { packerId: payload.packerId }
        )
        .then(resp => {
          resolve(resp.data.content[0]);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  addPacker(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .post(utils.sanitizeApiUrl(`/api/packers/user`), payload)
        .then(resp => {
          resolve(resp.data.content[0]);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  packingMistake(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .put(
          utils.sanitizeApiUrl(
            `/api/packers/deliveries/${payload.deliveryId}/packingerror`
          ),
          payload.data
        )
        .then(resp => {
          resolve(resp.data.content[0]);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getPackingMistakes(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .get(utils.sanitizeApiUrl(`/api/packers/packingerror`), {
          params: payload,
          noTimestamp: true
        })
        .then(resp => {
          resolve(resp.data.content);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const getters = {
  portalUsersGetter: state => {
    return state.portalUsers;
  },
  packersGetter: state => {
    return state.portalUsers.filter(p => {
      const isPacker = includes('packer', p.type);
      const isPackOnly = includes('packonly', p.type);
      const isAdminOrCustomerService =
        includes('admin', p.type) || includes('customerservice', p.type);

      return isPacker || (isPackOnly && !isAdminOrCustomerService);
    });
  },
  adminUsersGetter: state => {
    // filter the portal users that have a type of admin
    return state.portalUsers.filter(p => includes('admin', p.type));
  },
  activePackersGetter: state => {
    // return a list of packers that habe a type of packer and are active
    return state.portalUsers.filter(p => {
      return includes('packer', p.type) && p.active;
    });
  }
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
