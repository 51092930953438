import http from '@/http';
import utils from '@/utils';

const stateObj = {
  activeSamples: []
};

const mutations = {
  set_active_samples(state, payload) {
    state.activeSamples = payload;
  }
};
const actions = {
  cancelSubscription(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .put(
          utils.sanitizeApiUrl(
            `api/packers/users/${payload.uid}/cancel_subscription`
          ),
          { reason: payload.reason }
        )
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  pauseSubscription(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .put(
          utils.sanitizeApiUrl(
            `api/packers/users/${payload.uid}/${
              payload.onTrial ? 'extendtrial' : 'pause_subscription'
            }`
          ),
          { reason: payload.reason, days: payload.length }
        )
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updatePaymentDate(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .put(
          utils.sanitizeApiUrl(
            `api/packers/user/${payload.uid}/subscription/dom`
          ),
          { dom: payload.dom }
        )
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateCatDetails({ rootState }, payload) {
    return new Promise((resolve, reject) => {
      http
        .put(
          utils.sanitizeApiUrl(`api/packers/user/${payload.uid}/cat_update`),
          { items: payload.data }
        )
        .then(resp => {
          rootState.core.currentUserProfile.subscription.subscription.cats =
            resp.data.content;
          rootState.core.currentUserProfile.user.cats = resp.data.content;

          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateAddress({ rootState }, payload) {
    return new Promise((resolve, reject) => {
      const data = payload.data;
      data.postcode = data.postcode.toUpperCase();
      http
        .put(
          utils.sanitizeApiUrl(`api/packers/user/${payload.uid}/address`),
          data
        )
        .then(resp => {
          rootState.core.currentUserProfile.user.addr1 =
            resp.data.content[0].address1;
          rootState.core.currentUserProfile.user.addr2 =
            resp.data.content[0].address2;
          rootState.core.currentUserProfile.user.addr3 =
            resp.data.content[0].address3;
          rootState.core.currentUserProfile.user.postcode =
            resp.data.content[0].postcode;
          resolve(resp);
        })
        .catch(err => {
          reject(err.response.data.message);
        });
    });
  },
  updateCatSubscription({ rootState }, payload) {
    return new Promise((resolve, reject) => {
      http
        .put(
          utils.sanitizeApiUrl(`api/packers/user/${payload.uid}/subscription`),
          { items: payload.data, delivery: payload.delivery }
        )
        .then(resp => {
          rootState.core.currentUserProfile.subscription.subscription.cats =
            resp.data.content;

          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  toggleUserSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http
        .put(
          utils.sanitizeApiUrl(`/api/packers/user/${payload.uid}/settings`),
          payload.data
        )
        .then(resp => {
          commit('update_user_settings', resp.data.content[0]);
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  toggleUserOption({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http
        .put(utils.sanitizeApiUrl(`/api/packers/user/${payload.uid}/options`), {
          [payload.data.key]: payload.data.value
        })
        .then(resp => {
          commit('update_user_settings', {
            [payload.data.key]: JSON.parse(payload.data.value)
          });
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  removeCat(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .delete(utils.sanitizeApiUrl(`/api/packers/user/cat/${payload.cid}`))
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  resetTaster(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .delete(
          utils.sanitizeApiUrl(
            `/api/packers/user/${payload.uid}/removesamplebox`
          )
        )
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  chargeNow(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .post(
          utils.sanitizeApiUrl(`/api/packers/users/${payload.id}/chargenow`),
          payload.data
        )
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getFreeSamples({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http
        .get(utils.sanitizeApiUrl(`/api/packers/users/${payload.id}/samples`))
        .then(resp => {
          commit('set_active_samples', resp.data.content[0].current);
          resolve(resp.data.content[0].current);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  overrideDelivery(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .put(
          utils.sanitizeApiUrl(
            `/api/packers/user/${payload.uid}/deliveryoverride`
          ),
          {
            delivery: payload.courier
          }
        )
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const getters = {
  activeSamplesGetter: state => state.activeSamples
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
