import http from '@/http';
import utils from '@/utils';

const stateObj = {
  shippingOptions: {
    lastChecked: null,
    all: [],
    taster: [],
    subscription: []
  }
};

const mutations = {
  set_shipping_options(state, payload) {
    state.activeShippingSelections = payload;
  },
  set_shipping(state, payload) {
    state.shippingOptions[payload.type] = payload.data;
    state.shippingOptions.lastChecked = new Date();
    utils.setStorage(`shippingOptions`, state.shippingOptions, 'local');
  },
  set_all_shipping(state, payload) {
    state.shippingOptions = payload;
  }
};
const actions = {
  getShippingOptions({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const config = {
        noTimestamp: true
      };
      if (payload) {
        config.params = payload;
      }

      const type = payload?.type || 'all';

      http
        .get(utils.sanitizeApiUrl(`/api/packers/deliveryoptions`), config)
        .then(resp => {
          commit('set_shipping', {
            type: type,
            data: resp.data.content
          });

          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const getters = {
  deliveryServicesGetter: state => state.shippingOptions,
  activeShippingSelectionsGetter: state => state.activeShippingSelections
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
