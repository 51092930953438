import http from "@/http";

const stateObj = {
  stockLevels: null,
  stockStats: null
};

const mutations = {
  set_stock_levels(state, payload) {
    state.stockLevels = payload;
  },
  set_stock_stats(state, payload) {
    state.stockStats = payload;
  }
};
const actions = {
  getStockLevels({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get("packers/product/stocklevels")
        .then(response => {
          commit("set_stock_levels", response.data.content);

          resolve(response);
        })
        .catch(err => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  },
  getStockLevel({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http
        .get(`packers/product/stocklevels/${payload}`)
        .then(response => {
          console.log(commit);
          resolve(response);
        })
        .catch(err => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  },
  updateStockLevel({ commit }, payload) {
    return new Promise((resolve, reject) => {
      console.log(payload);
      http
        .put(`packers/product/${payload.id}/stocklevel`, {
          stocklevel: payload.stockLevel
        })
        .then(response => {
          console.log(commit);
          resolve(response);
        })
        .catch(err => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  },
  getStockStats({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get("packers/product_stats")
        .then(response => {
          commit("set_stock_stats", response.data.content);

          resolve(response);
        })
        .catch(err => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  }
};

const getters = {
  getCurrentStockLevels: state => {
    return state.stockLevels;
  },
  getCurrentStockStats: state => {
    return state.stockStats;
  }
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
