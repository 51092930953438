import http from '@/http';
import utils from '@/utils';

const stateObj = {
  churnList: [],
  churnCount: 0,
  defaultDays: 12
};

const mutations = {
  set_churn_list(state, payload) {
    state.churnList = payload;
  },
  set_churn_count(state, payload) {
    state.churnCount = payload;
  }
};
const actions = {
  getChurnList({ commit }, days) {
    return new Promise((resolve, reject) => {
      const config = {
        noTimestamp: true
      };
      http
        .get(
          utils.sanitizeApiUrl(
            `/api/packers/upcomingfirstsubscriptions?days=${days}`
          ),
          config
        )
        .then(resp => {
          commit('set_churn_list', resp.data.content);
          commit('set_churn_count', resp.data.content.length);
          resolve(resp.data.content);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const getters = {
  churnListGetter: state => state.churnList,
  churnCountGetter: state => state.churnCount
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
